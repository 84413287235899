import React, { useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";

const CustomButton = ({ onClick }) => {
  const medium = useMediaQuery({ query: "(min-width: 768px)" });
  const [isSending, setIsSending] = useState(false);
  const [hover, setHover] = useState(false);
  const [buttonClicked, setButtonClicked] = useState(false);

  return (
    <Button
      className="custom-button d-flex justify-content-center align-items-center px-5 py-2 py-lg-3 position-relative"
      type="submit"
      disabled={isSending}
      onClick={onClick}
      style={{
        width: medium ? "314px" : "184px",
        height: medium ? "58px" : "38px",
        fontWeight: "bold",
        border: "none",
        borderRadius: 0,
        background: "linear-gradient(90deg, #1f1715 0%, #272727 100%)",
      }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      // onClick={() => setButtonClicked(true)}
    >
      {hover && (
        <div
          className={`position-absolute cursor-pointer ${
            buttonClicked
              ? "button-clicked-interaction"
              : "button-hover-interaction"
          }`}
          style={{
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background: buttonClicked
              ? "rgba(0, 0, 0, 0)"
              : "rgba(0, 0, 0, 0.3)",
          }}
        ></div>
      )}
      <span
        className="lato font-bold button"
        style={{
          fontSize: medium ? "13.32px" : "9.81px",
          lineHeight: "140%",
          letterSpacing: "1.3px",
          textShadow: "2px 8px 6px rgba(0, 0, 0, 0.9)",
        }}
      >
        watch trailer
      </span>
    </Button>
  );
};

export default CustomButton;
