import React, { useEffect, useRef, useState } from "react";
import { Container, Nav, Navbar, Offcanvas } from "react-bootstrap";
import {
  blue_squirrel_logo,
  default_logo,
  line_md_menu_unfold_left,
  menu_back_icon,
} from "assets";
import { useMediaQuery } from "react-responsive";

const navRoutes = [
  { title: "About", path: "#about" },
  { title: "Our Games", path: "#our-games" },
  { title: "Team", path: "#team" },
  { title: "Contact Us", path: "#contact" },
];

const SiteNavbar = ({
  showLogo = true,
  showBackIcon,
  backAction,
  showToggleIcon = true,
  title,
  showTitleOnLg,
  className,
  style,
}) => {
  const medium = useMediaQuery({ query: "(min-width: 768px)" });
  const offCanvasRef = useRef(null);
  // const params = location.pathname.split("/").filter((item) => item !== "");
  const [open, setOpen] = useState(false);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [isHovered, setIsHovered] = useState(false);

  const handleOffCanvasToggle = () => {
    setOpen(!open);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        offCanvasRef.current &&
        !offCanvasRef.current.contains(event.target) &&
        open
      ) {
        handleOffCanvasToggle();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [open, handleOffCanvasToggle]);

  return (
    <Navbar
      expand={false}
      className={"px-4 py-md-4"}
      style={{
        minHeight: 76,
        // backgroundColor: "rgba(0,0,0,0.6)",
        // backdropFilter: "blur(37.6px)",
        ...style,
      }}
    >
      <Container
        fluid
        className="d-flex justify-content-between align-items-center"
      >
        <Navbar.Brand
          href="/"
          className="d-flex align-items-center justify-content-center cursor-pointer text-white"
          style={{ zIndex: 2 }}
        >
          <img
            src={blue_squirrel_logo}
            alt="blue squirrel studios"
            className="img-fluid cursor-pointer"
            style={{
              objectFit: "contain",
              width: medium ? "244" : "89px",
              height: medium ? "83px" : "32px",
            }}
          />
          {/* <p
            className="mb-0 cursor-pointer navbar-header d-flex align-items-center bebas-neue"
            style={{
              fontSize: "18px",
              textTransform: "uppercase",
              lineHeight: "140%",
              letterSpacing: "0.68px",
            }}
          >
            blue squirrel studios
          </p> */}
        </Navbar.Brand>

        {!open && showToggleIcon && (
          <Navbar.Toggle
            aria-controls="offcanvasNavbar-expand-false"
            className="border-0 p-1 focus-none "
            onClick={handleOffCanvasToggle}
          >
            <img
              src={line_md_menu_unfold_left}
              alt="line_md_menu_unfold_left"
              className="focus-none img-fluid"
              style={{
                width: medium ? "24px" : "19px",
                height: medium ? "14px" : "11px",
                filter: isHovered ? "brightness(100) saturate(0%)" : "none",
              }}
              onMouseEnter={() => {
                setIsHovered(true);
              }}
              onMouseLeave={() => {
                setIsHovered(false);
              }}
            />
          </Navbar.Toggle>
        )}

        <Navbar.Offcanvas
          id="offcanvasNavbar-expand-false"
          aria-labelledby="offcanvasNavbarLabel-expand-false"
          placement="end"
          show={open}
          className="bg-transparent text-white"
          style={{
            width: 400,
            backgroundImage:
              "linear-gradient(to right, rgba(0, 0, 0, 0),rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 1))",
          }}
        >
          <Offcanvas.Body>
            <div
              className="d-flex justify-content-end align-items-start"
              ref={offCanvasRef}
            >
              <div className="d-flex flex-column align-items-end">
                <div className="d-flex flex-column align-items-end me-1">
                  <div
                    onClick={handleOffCanvasToggle}
                    className="ms-auto text-left me-5 mb-2 mt-5 pb-5 cursor-pointer"
                    // style={{ width: 40, height: 100 }}
                  >
                    <img
                      src={line_md_menu_unfold_left}
                      alt="close menu"
                      className="img-fluid"
                      style={{
                        width: medium ? "24px" : "19px",
                        height: medium ? "14px" : "11px",
                      }}
                    />
                  </div>
                </div>
                {navRoutes.map((route, i) => (
                  <a
                    key={i}
                    href={`/${route.path}`}
                    className="d-flex flex-column justify-content-center pb-2 text-decoration-none nav-route"
                    onMouseEnter={() => setHoveredIndex(i)}
                    onMouseLeave={() => setHoveredIndex(null)}
                    onClick={handleOffCanvasToggle}
                  >
                    <div
                      className={`lato font-lg text-white mb-5 me-5 px-2 d-flex justify-content-end align-items-center ${
                        i === 0 || i === 2 ? "bounce-in-right-link" : ""
                      }`}
                      style={{
                        width: "240px",
                        height: "42px",
                        fontSize: "18.32px",
                        textShadow: "2px 8px 6px rgba(0, 0, 0, 0.9)",
                      }}
                    >
                      {route.title}
                    </div>
                  </a>
                ))}
              </div>
            </div>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Container>
    </Navbar>
  );
};

export default SiteNavbar;
