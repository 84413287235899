import Footer from "components/Footer";
import SiteNavbar from "components/Navbar";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";

const PrivacyPolicy = () => {
  const medium = useMediaQuery({ query: "(min-width: 768px)" });

  return (
    <Container fluid className="px-0 section-bg">
      <SiteNavbar />
      <Container
        fluid
        className="px-5 d-flex align-items-start justify-content-center"
      >
        <div
          className="d-flex flex-column align-items-center  privacy-bg"
          md={12}
        >
          <div className="d-none d-sm-flex align-items-center my-5">
            <p className="lato font-lg font-bold">Privacy Policy</p>
          </div>

          <Row className="m-0">
            <Col xs={12} sm={12} md={12} className="p-4 about-cards mb-3">
              <div
                className={`${
                  medium
                    ? "p-5 mx-5"
                    : "d-flex flex-column justify-content-center"
                }`}
              >
                <p className="privacy-policy-text">
                  This Privacy Policy (“Policy”) provides for Blue Squirrel
                  Studios’ (“Blue Squirrel Studios,” “we” “our” or “us”) policy
                  regarding collection, sharing, and use of information provided
                  by you (“you” “your”). By continuing the use of our platform
                  services (“Platform”) to sell and buy your products, you
                  signify your acceptance of this Policy.
                </p>

                <p className="privacy-policy-headings">
                  Information We Collect{" "}
                </p>
                <p className="privacy-policy-text">
                  While some parts of the Platform allow you to visit as guest
                  and remain anonymous, restricting your access to certain
                  content and features of the platform, you may need to provide
                  us with certain information for registering as seller on the
                  Platform. For instance, you may need to provide us with
                  information including but not limited to name, birth date, a
                  profile name, payment-related information, phone number,
                  password, email, and zip/postal code and other similar
                  information. Once you give us your personal information, you
                  are not anonymous to us. Where possible, we indicate which
                  fields are required and which fields are optional. The post
                  messages on our message boards, personalised messages, images,
                  photos, gift card message box, chat rooms or other message
                  areas or leave feedback/product review on the Platform, are
                  collected by us. Please note such messages posted by you in
                  public domain and can be read by others as well, please
                  exercise caution while posting such messages, personal
                  details, photos and reviews. We retain this information as
                  necessary to resolve disputes, provide customer support,
                  internal research and troubleshoot problems as permitted by
                  law. If you send us personal correspondence, such as emails or
                  letters, or if other users or third parties send us
                  correspondence about your activities or postings on the
                  Platform, we may collect such information into a file specific
                  to you.{" "}
                </p>
                <p className="privacy-policy-headings">
                  Information We Collect Automatically
                </p>
                <p>
                  We collect some information automatically when you visit,
                  access, or use the Platform. This includes information about
                  the application usage, user interactions, purchases, and other
                  activity on the Platform, typically associated with your
                  account profile or with an identifier we have assigned to your
                  device or profile. Our primary goal in doing this is to
                  provide you a safe, efficient, smooth, and customized
                  experience. This allows us to provide services and features
                  that most likely meet your needs and to customize our Platform
                  to make your experience safer and easier. While the specific
                  types of information that we automatically collect may vary,
                  they generally include:
                  <ol>
                    <li>
                      Usage information and statistics about how you interact
                      with the Platform, including the application you used,
                      saved preferences, crash reports, and navigation data
                      (like the URLs of our websites that you have visited, URLs
                      of referring and exiting pages, page views, time spent on
                      a page);
                    </li>
                    <li>
                      Technical information about your computer, device,
                      hardware, or software you use to access our Platform, such
                      as IP address, device identifiers, your internet service
                      provider, plugins, or other transactional or identifier
                      information for your device (such as device make and
                      model, information about device operating systems and
                      browsers, or other device or system-related
                      specifications); and
                    </li>
                    <li>
                      The general location of your device, which we typically
                      derive from your device's IP address.
                    </li>
                  </ol>{" "}
                </p>
                <p className="privacy-policy-headings">Third Party Websites</p>
                <p className="privacy-policy-text">
                  You understand that the Platform may contain links to other
                  websites that we do not operate. If you click on a third-party
                  link, you will be directed to that third party's site. We
                  encourage you to review the privacy policy of every site you
                  visit. We shall not be responsible for the third-party
                  business partner’s privacy practices or the content of their
                  privacy policies, and we request you to read their privacy
                  policies prior to disclosing any information.{" "}
                </p>
                <p className="privacy-policy-headings">Use</p>
                <p className="privacy-policy-text">
                  We use personal information to provide the services you
                  request. We use your personal information to assist sellers
                  and business partners in handling and fulfilling orders;
                  enhancing customer experience; to resolve disputes;
                  troubleshoot problems; help promote a safe service; collect
                  money; measure consumer interest in our products and services,
                  inform you about online and offline offers, products,
                  services, and updates; customize your experience; detect and
                  protect us against error, fraud and other criminal activity;
                  enforce our terms and conditions; conduct marketing research,
                  analysis and surveys; and as otherwise described to you at the
                  time of collection of information. We will ask for your
                  permission to allow us access to your text messages (SMS),
                  instant messages, contacts in your directory, camera, photo
                  gallery, location and device information: (i) to send
                  commercial communication regarding your orders or other
                  products and services (ii) enhance your experience on the
                  platform and provide you access to the products and services
                  offered on the Platform by sellers, affiliates, partners or
                  lending partners. You understand that your access to these
                  products/services may be affected in the event permission is
                  not provided to us. In our efforts to continually improve our
                  product and service offerings, we collect and analyse
                  demographic and profile data about users' activity on our
                  Platform. We identify and use your IP address to help diagnose
                  problems with our server, and to administer our Platform. Your
                  IP address is also used to help identify you and to gather
                  broad demographic information. We will occasionally ask you to
                  complete surveys conducted either by us or through a third-
                  party market research agency.
                </p>
                <p className="privacy-policy-text">
                  We may also process information that does not identify you
                  individually, including aggregate or de- identified
                  information that we create or collect from other sources. This
                  information helps us understand larger groups of users better.
                  If we combine this information with information that
                  identifies you, we shall treat it as described in this Policy.
                  Please note that this Policy does not restrict our ability to
                  process information that does not individually identify you,
                  and we may use and disclose aggregated or de- identified
                  information for any reason permitted by law.
                </p>
                <p className="privacy-policy-headings">
                  Recipients of Your Information
                </p>
                <p className="privacy-policy-text">
                  We may share your information for the business purposes with
                  third party entities including but not limited to:
                  <ul>
                    <li>
                      Our affiliates, business partners, suppliers, and
                      sub-contractors for the performance of any contract we
                      enter into with them or you, for example for hosting and
                      operating the Platform or providing support and
                      maintenance services for the Platform;
                    </li>
                    <li>
                      Analytics and search engine providers and other selected
                      third parties that assist us in the improvement and
                      optimization of our Platform;
                    </li>
                    <li>
                      Our regulators, law enforcement, or fraud prevention
                      agencies, as well as our advisors (including legal
                      advisors and auditors), courts, and any other authorized
                      bodies, for the purposes of investigating any actual or
                      suspected criminal activity or other regulatory or legal
                      matters;
                    </li>
                    <li>
                      In the event of restructuring or sale of all or a
                      significant part of our business;
                    </li>
                    <li>
                      To protect the rights, property, or safety of Blue
                      Squirrel Studios, our customers, or others.
                    </li>
                  </ul>
                </p>
                <p className="privacy-policy-headings">Privacy Settings</p>
                <p className="privacy-policy-text">
                  Depending on your interaction with the Platform, We seek to
                  provide you with meaningful choices about the personal data we
                  collect. The specific choices available to you often vary
                  depending on how you interact with the Platform:
                  <ul>
                    <li>
                      If we process your personal data based on your consent,
                      you have the right to withdraw your consent at any time by
                      updating the settings in your user profile or contacting
                      us at support.dts@bluesquirrelstudios.com.
                    </li>
                    <li>
                      You can also change your email marketing preferences at
                      any time by using the opt-out mechanism provided in our
                      marketing emails.
                    </li>
                    <li>
                      If you wish to modify any of your user profile
                      information, restrict the processing of your personal
                      data, or cancel your account, you may update the settings
                      in your user profile or contact us at
                      support.dts@bluesquirrelstudios.com.
                    </li>
                    <li>
                      You can request that we provide access to, or that we
                      correct or delete, personal information we've collected
                      from you. Please submit requests to access, update, or
                      delete personal information associated with your Blue
                      Squirrel Studios account by contacting us at
                      talk2us@BlueSquirrelStudios.com. Note that we may ask you
                      for additional information to help us verify who you are
                      before completing your request.
                    </li>
                    <li>
                      You can change your browser or mobile device settings to
                      block, manage, delete, or limit tracking technologies like
                      cookies. In some cases, blocking or disabling cookies may
                      cause the Platform not to work as intended and some
                      features may not be available.
                    </li>
                  </ul>
                </p>
                <p className="privacy-policy-headings">
                  Security of the Personal Information
                </p>
                <p className="privacy-policy-text">
                  While we take precautions against all possible security breach
                  in our Platform, Consequently, we cannot guarantee that the
                  Platform shall be free from unauthorized access, hacking, data
                  loss, or other breaches. We urge you to take steps to keep
                  your personal information safe by memorizing your password or
                  storing it safely (separate from your account information),
                  logging out of your user account, keeping your OTP secret and
                  closing your web browser whenever you finish your session or
                  leave your computer in a public area. We employ a security
                  technology known as a Secure Sockets Layer (SSL) protocol to
                  protect the transmission of critical account information.
                </p>
                <p className="privacy-policy-headings">Disclaimer</p>
                <p className="privacy-policy-text">
                  YOU ARE ADVISED TO REVIEW THIS PRIVACY POLICY CAREFULLY SO
                  THAT THE YOU UNDERSTAND OUR PRIVACY PRACTICES. BY USING OUR
                  PLATFORM, YOU WILL DEEMED TO HAVE READ, UNDERSTOOD AND AGREED
                  TO THE PRACTICES AND POLICIES OUTLINED IN THIS PRIVACY POLICY
                  AND AGREE TO BE BOUND BY THE PRIVACY POLICY. YOU HEREBY
                  CONSENT TO OUR COLLECTION, USE, STORING AND SHARING OF YOUR
                  PERSONAL INFORMATION AS DESCRIBED IN THIS PRIVACY POLICY. IF
                  YOU USE THE SERVICES ON BEHALF OF SOMEONE ELSE, YOU REPRESENT
                  THAT YOU ARE AUTHORISED BY SUCH INDIVIDUAL OR ARE LEGALLY
                  AUTHORIZED TO (I) ACCEPT THIS PRIVACY POLICY ON SUCH
                  INDIVIDUAL’S BEHALF, AND (II) CONSENT ON BEHALF OF SUCH
                  INDIVIDUAL TO OUR COLLECTION, USE AND DISCLOSURE OF SUCH
                  INDIVIDUAL’S PERSONAL INFORMATION AS DESCRIBED IN THIS PRIVACY
                  POLICY. YOU FURTHER UNDERSTAND THAT THIS PLATFORM MAY CONTAIN
                  LINKS TO OTHER SITES THAT WE DO NOT OPERATE. IF YOU CLICK ON A
                  THIRD-PARTY LINK, YOU WILL BE DIRECTED TO THAT THIRD PARTY'S
                  SITE. WE ADVISE YOU TO REVIEW THE PRIVACY POLICY OF EVERY SITE
                  YOU VISIT. WE HAVE NO CONTROL OVER AND ASSUME NO
                  RESPONSIBILITY FOR THE CONTENT, PRIVACY POLICIES OR PRACTICES
                  OF ANY THIRD-PARTY SITES OR SERVICES.
                </p>
                <p className="privacy-policy-headings">Children's Privacy</p>
                <p className="privacy-policy-text">
                  Use of our Platform is available only to persons who can form
                  a legally binding contract under the applicable law. We do not
                  knowingly solicit or collect personal information from
                  children under the age of 18 years. If you have shared any
                  personal information of children under the age of 18 years,
                  you represent that you have the authority to do so and permit
                  us to use the information in accordance with this Privacy
                  Policy.{" "}
                </p>
                <p className="privacy-policy-headings">Data Retention</p>
                <p className="privacy-policy-text">
                  We retain your personal information for a period no longer
                  than is required for the purpose for which it was collected or
                  as required under any applicable law. However, we may retain
                  data related to you if there is a legal obligation to retain
                  the data; if required to comply with any applicable statutory
                  or regulatory retention requirement by law; if we believe it
                  may be necessary to prevent fraud or future abuse; to enable
                  Blue Squirrel Studios to exercise its legal rights and/or
                  defend against legal claims; or for other legitimate purposes.
                  We may continue to retain your data in anonymised form for
                  analytical and research purposes.{" "}
                </p>
                <p className="privacy-policy-headings">Consent</p>
                <p className="privacy-policy-text">
                  By visiting our Platform or by providing your information, you
                  consent to the collection, use, storage, transfer, disclosure
                  and otherwise processing of your information (including
                  sensitive personal information) on the Platform in accordance
                  with this Privacy Policy. If you disclose to us any personal
                  information relating to other people, you represent that you
                  have the authority to do so and permit us to use the
                  information in accordance with this Privacy Policy. You, while
                  providing your personal information over the Platform or any
                  partner platforms or establishments, consent to us (including
                  our other corporate entities, affiliates, lending partners,
                  technology partners, marketing channels, business partners and
                  other third parties) to contact you through SMS, instant
                  messaging apps, call and/or e-mail for the purposes specified
                  in this Privacy Policy. You have an option to withdraw your
                  consent that you have already provided by writing to the
                  Grievance Officer at the contact information provided below.
                  Please mention “Withdrawal of consent for processing personal
                  information” in your subject line of your communication. We
                  will verify such requests before acting on our request. Please
                  note, however, that withdrawal of consent will not be
                  retroactive and will be in accordance with the terms of this
                  Privacy Policy, related terms of use and applicable laws. In
                  the event you withdraw consent given to us under this Privacy
                  Policy, we reserve the right to restrict or deny the provision
                  of our services for which we consider such information to be
                  necessary.{" "}
                </p>
                <p className="privacy-policy-headings">
                  Changes to this Policy
                </p>
                <p className="privacy-policy-text">
                  We may update this Policy from time to time. If the changes
                  materially affect the way we use identifiable information that
                  we have already collected, we shall notify you on our
                  Platform. The continued usage of our Platform after such
                  changes have been made to these terms will mean that you
                  accept these changes. Therefore, you are requested to kindly
                  check the Policy regularly for updates.{" "}
                </p>
                <p className="privacy-policy-headings">GRIEVANCE OFFICER</p>
                <p className="privacy-policy-text">
                  In the event of any complaint or issue with respect to the
                  contents of the Platform, you may contact our Grievance
                  Officer at:
                </p>

                <p className="privacy-policy-text">
                  <strong>Name:</strong> Reema Maheshwari
                </p>
                <p className="privacy-policy-text">
                  <strong>Designation:</strong> Co-Founder
                </p>
                <p className="privacy-policy-text">
                  <strong>Address:</strong> Pune, Maharashtra
                </p>
                <p className="privacy-policy-text">
                  <strong>Email:</strong>
                  <a href="mailto:support.dts@bluesquirrelstudios.com">
                    support.dts@bluesquirrelstudios.com
                  </a>
                </p>
                <p className="privacy-policy-text">
                  <strong>Phone:</strong> 1800-266-4020
                </p>
                <p className="privacy-policy-text">
                  <strong>Time:</strong> 10:00 IST to 16:00 IST
                </p>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
      <Footer />
    </Container>
  );
};

export default PrivacyPolicy;
