import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Row, Col, Card, Button, Image } from "react-bootstrap";
import {
  blue_squirrel_logo,
  blue_squirrel_vector,
  capsule_1,
  capsule_2,
  contact_us_bg,
  contact_us_bg_mobile,
  domiante_the_skies_poster,
  footer_bg_right,
  frame_1,
  frame_10,
  frame_11,
  frame_12,
  frame_13,
  frame_14,
  frame_2,
  frame_3,
  frame_4,
  frame_5,
  frame_6,
  frame_7,
  frame_8,
  frame_9,
  landing_bg,
  lets_play_neon,
  linkedin,
  logo,
} from "assets";
import "App.css";
import "App.scss";
import SiteNavbar from "components/Navbar";
import CustomButton from "components/CustomButton";
import ContactUsForm from "components/ContactUsForm";
import Footer from "components/Footer";
import { useMediaQuery } from "react-responsive";
import HomeVideoContainer from "components/HomeVideoContainer";

const Home = () => {
  const medium = useMediaQuery({ query: "(min-width: 768px)" });

  const cardData = [
    {
      id: 1,
      title: "Arindom Chatterjee",
      description: `Arindom's life is an intricate tapestry of diverse experiences, interests, and skills.
Whether it's his love for dogs, his interest in VR, his passion about leaving behind a
more habitable world for future generations, his penchant for crazy ideas, or his
extensive corporate experience, every thread adds unique color and texture to his
life.`,
      linkedinUrl: "https://www.linkedin.com/in/arindomchatterjee/",
    },
    {
      id: 2,
      title: "Reema Maheshwari",
      description: `Reema brings over two decades of experience, including a decade and half of
building and operating a successful human resources company. She combines astute
business acumen, exceptional interpersonal skills, and a deep understanding of
people to excel in her endeavours.`,
      linkedinUrl: "https://www.linkedin.com/in/reemamaheshwari/",
    },
    {
      id: 3,
      title: "Apar",
      description: `Apar is a creative professional having worked as a researcher, designer, producer and
advisor with 25 years of experience in the gaming industry. He has managed and
executed gaming projects, leading diverse multicultural teams across various
geographic locations for clients in various domains. He is a passionate gamer who
advocates for a player first approach and is often found playing World of Warcraft or
learning new languages in his free time.`,
      linkedinUrl: "https://www.linkedin.com/in/aparmaniar/",
    },
  ];

  const images = [
    { id: 1, u_id: "c1", img: frame_1 },
    { id: 2, u_id: "c2", img: frame_2 },
    { id: 3, u_id: "c3", img: frame_3 },
    { id: 4, u_id: "c4", img: frame_4 },
    { id: 5, u_id: "c5", img: frame_5 },
    { id: 6, u_id: "c6", img: frame_6 },
    { id: 7, u_id: "c7", img: frame_7 },
    { id: 8, u_id: "c8", img: frame_8 },
    { id: 9, u_id: "c9", img: frame_9 },
    { id: 10, u_id: "c10", img: frame_10 },
    { id: 11, u_id: "c11", img: frame_11 },
    { id: 12, u_id: "c11", img: frame_12 },
  ];

  // const leftImages = [
  //   { id: 1, u_id: "L1", img: frame_5 },
  //   { id: 2, u_id: "L2", img: frame_6 },
  //   { id: 3, u_id: "L3", img: frame_7 },
  //   { id: 4, u_id: "L4", img: frame_8 },
  //   { id: 5, u_id: "L5", img: frame_9 },
  //   { id: 6, u_id: "L6", img: frame_2 },
  // ];

  // const rightImages = [
  //   { id: 1, u_id: "R1", img: frame_5 },
  //   { id: 2, u_id: "R2", img: frame_6 },
  //   { id: 3, u_id: "R3", img: frame_7 },
  //   { id: 4, u_id: "R4", img: frame_8 },
  //   { id: 5, u_id: "R5", img: frame_9 },
  //   { id: 6, u_id: "R6", img: frame_2 },
  // ];

  // const leftImagesMob = [
  //   { id: 1, u_id: "LM1", img: frame_5 },
  //   { id: 2, u_id: "LM2", img: frame_6 },
  //   { id: 3, u_id: "LM3", img: frame_7 },
  // ];

  // const rightImagesMob = [
  //   { id: 1, u_id: "L1", img: frame_5 },
  //   { id: 2, u_id: "L2", img: frame_6 },
  //   { id: 3, u_id: "L3", img: frame_7 },
  // ];

  const [currentImages, setCurrentImages] = useState(images);
  const [showVideo, setShowVideo] = useState(false);
  // const [isHovered, setIsHovered] = useState(null);

  const [trackPointer, setTrackPointer] = useState(null);

  useEffect(() => {
    const shuffleImages = () => {
      currentImages.forEach((_, index) => {
        setTimeout(() => {
          setCurrentImages((prevImages) => {
            const shuffledImages = shuffleArray(prevImages);
            return shuffledImages;
          });
        }, index * 800);
      });
    };

    const intervalId = setInterval(() => {
      shuffleImages();
    }, 800);

    return () => clearInterval(intervalId);
  }, [currentImages]);

  // useEffect(() => {
  //   const shuffleImages = () => {
  //     setCurrentImages((prevImages) => {
  //       const shuffledImages = shuffleArray([...prevImages]);
  //       return shuffledImages;
  //     });
  //   };

  //   const intervalId = setInterval(shuffleImages, 800); // Shuffle every 1.5 seconds

  //   return () => clearInterval(intervalId);
  // }, []);

  const shuffleArray = (array) => {
    let shuffled = [...array];
    for (let i = shuffled.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
    }
    return shuffled;
  };

  const handleButtonClick = () => {
    setShowVideo((prevShowVideo) => !prevShowVideo);
  };

  // const images = [
  //   { id: 1, u_id: "c1", img: frame_1 },
  //   { id: 2, u_id: "c2", img: frame_2 },
  //   { id: 3, u_id: "c3", img: frame_3 },
  //   { id: 4, u_id: "c4", img: frame_4 },
  //   { id: 5, u_id: "c5", img: frame_5 },
  //   { id: 6, u_id: "c6", img: frame_6 },
  //   { id: 7, u_id: "c7", img: frame_7 },
  //   { id: 8, u_id: "c8", img: frame_8 },
  //   { id: 9, u_id: "c9", img: frame_9 },
  // ];

  return (
    <Container fluid className="px-0" style={{ background: "rgba(0,0,0,1)" }}>
      {/* <Container
        fluid
        id="home"
        className="px-0 d-flex align-items-center"
        style={{
          background: "rgba(0,0,0,1)",
          minHeight: "100vh",
          overflowX: "hidden",
        }}
      >
        {medium ? (
          <div className="w-100 d-flex justify-content-between">
            <div className="d-flex left-half-visible">
              <Col className="side-grid faded-grid custom-col-width">
                {leftImages.slice(0, 3).map((image, idx) => (
                  <div key={idx} className="mb-4">
                    <Image
                      src={image.img}
                      onMouseEnter={() => setIsHovered(image.u_id)}
                      onMouseLeave={() => setIsHovered(null)}
                      fluid
                      className="faded-image"
                      style={{
                        filter:
                          isHovered === image.u_id ? "none" : "grayscale(100%)",
                      }}
                    />
                  </div>
                ))}
              </Col>
              <Col className="side-grid faded-grid custom-col-width">
                {leftImages.slice(3, 6).map((image, idx) => (
                  <div key={idx} className="mb-4">
                    <Image
                      src={image.img}
                      onMouseEnter={() => setIsHovered(image.u_id)}
                      onMouseLeave={() => setIsHovered(null)}
                      style={{
                        filter:
                          isHovered === image.u_id ? "none" : "grayscale(100%)",
                      }}
                      fluid
                      className="faded-image"
                    />
                  </div>
                ))}
              </Col>
            </div>

            <Col xs={6} className="animated-grid custom-col-width-center">
              <Row>
                {currentImages.slice(0, 3).map((image, idx) => (
                  <Col
                    key={idx}
                    xs={4}
                    className="mb-4 p-0"
                    onMouseEnter={() => setTrackPointer(1 + idx)}
                    onMouseLeave={() => setTrackPointer(0)}
                  >
                    <Image
                      src={image.img}
                      style={{
                        filter:
                          trackPointer === 1 + idx ? "none" : "grayscale(100%)",
                      }}
                      fluid
                      className="center-image"
                    />
                  </Col>
                ))}
              </Row>
              <Row>
                {currentImages.slice(3, 6).map((image, idx) => (
                  <Col
                    key={idx}
                    xs={4}
                    className="mb-4 p-0"
                    onMouseEnter={() => setTrackPointer(4 + idx)}
                    onMouseLeave={() => setTrackPointer(0)}
                  >
                    <Image
                      src={image.img}
                      style={{
                        filter:
                          trackPointer === 4 + idx ? "none" : "grayscale(100%)",
                      }}
                      fluid
                      className="center-image"
                    />
                  </Col>
                ))}
              </Row>
              <Row>
                {currentImages.slice(6, 9).map((image, idx) => (
                  <Col
                    key={idx}
                    xs={4}
                    className="mb-4 p-0"
                    onMouseEnter={() => setTrackPointer(7 + idx)}
                    onMouseLeave={() => setTrackPointer(0)}
                  >
                    <Image
                      src={image.img}
                      onMouseEnter={() => setIsHovered(image.u_id)}
                      style={{
                        filter:
                          trackPointer === 7 + idx ? "none" : "grayscale(100%)",
                      }}
                      fluid
                      className="center-image"
                    />
                  </Col>
                ))}
              </Row>
            </Col>
            <div className="d-flex right-half-visible">
              <Col className="side-grid faded-grid custom-col-width">
                {rightImages.slice(0, 3).map((image, idx) => (
                  <div key={idx} className="mb-4">
                    <Image
                      src={image.img}
                      onMouseEnter={() => setIsHovered(image.u_id)}
                      onMouseLeave={() => setIsHovered(null)}
                      style={{
                        filter:
                          isHovered === image.u_id ? "none" : "grayscale(100%)",
                      }}
                      fluid
                      className="faded-image"
                    />
                  </div>
                ))}
              </Col>
              <Col className="side-grid faded-grid custom-col-width">
                {rightImages.slice(3, 6).map((image, idx) => (
                  <div key={idx} className="mb-4">
                    <Image
                      src={image.img}
                      onMouseEnter={() => setIsHovered(image.u_id)}
                      onMouseLeave={() => setIsHovered(null)}
                      style={{
                        filter:
                          isHovered === image.u_id ? "none" : "grayscale(100%)",
                      }}
                      fluid
                      className="faded-image"
                    />
                  </div>
                ))}
              </Col>
            </div>
          </div>
        ) : (
          <div className="w-100 mb-5 pb-5 d-flex justify-content-between align-items-center">
            <div className="d-flex left-half-visible">
              <Col className="side-grid faded-grid custom-col-width">
                {leftImages.slice(3, 6).map((image, idx) => (
                  <div key={idx} className="">
                    <Image
                      src={image.img}
                      onMouseEnter={() => setIsHovered(image.u_id)}
                      style={{
                        filter:
                          isHovered === image.u_id ? "none" : "grayscale(100%)",
                      }}
                      fluid
                      className="faded-image m-2"
                    />
                  </div>
                ))}
              </Col>
            </div>
            <Col xs={6} className="animated-grid custom-col-width-center">
              <Row>
                {currentImages.slice(0, 3).map((image, idx) => (
                  <Col key={idx} xs={4} className="p-0">
                    <Image
                      src={image.img}
                      onMouseEnter={() => setIsHovered(image.u_id)}
                      style={{
                        filter:
                          isHovered === image.u_id ? "none" : "grayscale(100%)",
                      }}
                      fluid
                      className="center-image m-2"
                    />
                  </Col>
                ))}
              </Row>
              <Row>
                {currentImages.slice(3, 6).map((image, idx) => (
                  <Col key={idx} xs={4} className="p-0">
                    <Image
                      src={image.img}
                      onMouseEnter={() => setIsHovered(image.u_id)}
                      style={{
                        filter:
                          isHovered === image.u_id ? "none" : "grayscale(100%)",
                      }}
                      fluid
                      className="center-image m-2"
                    />
                  </Col>
                ))}
              </Row>
              <Row>
                {currentImages.slice(6, 9).map((image, idx) => (
                  <Col key={idx} xs={4} className="p-0">
                    <Image
                      src={image.img}
                      onMouseEnter={() => setIsHovered(image.u_id)}
                      style={{
                        filter:
                          isHovered === image.u_id ? "none" : "grayscale(100%)",
                      }}
                      fluid
                      className="center-image m-2"
                    />
                  </Col>
                ))}
              </Row>
            </Col>
            <div className="d-flex right-half-visible">
              <Col className="side-grid faded-grid custom-col-width">
                {rightImages.slice(0, 3).map((image, idx) => (
                  <div key={idx} className="">
                    <Image
                      src={image.img}
                      onMouseEnter={() => setIsHovered(image.u_id)}
                      style={{
                        filter:
                          isHovered === image.u_id ? "none" : "grayscale(100%)",
                      }}
                      fluid
                      className="faded-image m-2"
                    />
                  </div>
                ))}
              </Col>
            </div>
          </div>
        )}
      </Container> */}
      <Container
        fluid
        id="home"
        className="px-0"
        style={{
          backgroundImage: `url(${landing_bg})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      >
        <Container
          fluid
          className="px-0 d-flex flex-column"
          style={{ minHeight: "100vh" }}
        >
          <SiteNavbar />
          <Container
            fluid
            className="px-0 d-flex justify-content-center align-items-center flex-grow-1"
            style={{
              color: "white",
            }}
          >
            <Row className="mb-5 mx-0 h-100 w-100 d-flex align-items-center">
              {medium ? (
                <>
                  <Col
                    md={6}
                    className="d-flex justify-content-center align-items-center"
                  >
                    <Image src={lets_play_neon} />
                  </Col>

                  <Col md={6}>
                    <div className="d-flex flex-column">
                      {[0, 3, 6].map((rowIndex) => (
                        <div
                          className="d-flex justify-content-center"
                          key={rowIndex}
                        >
                          {currentImages
                            .slice(rowIndex, rowIndex + 3)
                            .map((img, index) => (
                              <div
                                className="m-3"
                                key={index}
                                style={{ width: "156px" }}
                              >
                                <Image
                                  src={img.img}
                                  onMouseEnter={() =>
                                    setTrackPointer(rowIndex + index + 1)
                                  }
                                  onMouseLeave={() => setTrackPointer(null)}
                                  alt={`Game image ${rowIndex + index + 1}`}
                                  fluid
                                  style={{
                                    width: "100%",
                                    filter:
                                      img.id == "12" ||
                                      trackPointer === rowIndex + index + 1
                                        ? "none"
                                        : "grayscale(100%)",
                                  }}
                                />
                              </div>
                            ))}
                        </div>
                      ))}
                    </div>
                  </Col>
                </>
              ) : (
                <>
                  <Col
                    md={6}
                    className="d-flex flex-column justify-content-center align-items-center"
                  >
                    <Col md={6}>
                      <div className="d-flex flex-column">
                        {[0, 3, 6].map((rowIndex) => (
                          <div
                            className="d-flex justify-content-center"
                            key={rowIndex}
                          >
                            {currentImages
                              .slice(rowIndex, rowIndex + 3)
                              .map((img, index) => (
                                <div
                                  className="m-3"
                                  key={index}
                                  style={{ width: "78px" }}
                                >
                                  <Image
                                    src={img.img}
                                    onClick={() =>
                                      setTrackPointer((prevPointer) =>
                                        prevPointer === rowIndex + index + 1
                                          ? null
                                          : rowIndex + index + 1
                                      )
                                    }
                                    alt={`Game image ${rowIndex + index + 1}`}
                                    fluid
                                    style={{
                                      width: "100%",
                                      filter:
                                        img.id == "12" ||
                                        trackPointer === rowIndex + index + 1
                                          ? "none"
                                          : "grayscale(100%)",
                                    }}
                                  />
                                </div>
                              ))}
                          </div>
                        ))}
                      </div>
                    </Col>
                    <Image src={lets_play_neon} style={{ width: "209px" }} />
                  </Col>
                </>
              )}
            </Row>
          </Container>
        </Container>
      </Container>

      <Container
        id="about"
        fluid
        className="px-5 section-bg d-flex align-items-start justify-content-center"
      >
        <div
          className="d-flex flex-column align-items-center justify-content-center about-content-bg"
          md={12}
        >
          <div className="d-none d-sm-flex align-items-center mb-3">
            <p className="lato font-lg font-bold">About</p>
          </div>

          <Row className="m-0">
            <Col xs={12} sm={12} md={12} className="p-4 about-cards mb-3">
              <div
                className={`${
                  medium
                    ? "p-5 mx-5"
                    : "d-flex flex-column justify-content-center"
                }`}
              >
                <p className="font-lg font-semibold">Vision</p>
                <p className="font-sm text-container">
                  We aim to be India's most admired indie game developer,
                  creating original, innovative, and immersive games that{" "}
                  <span class="break-text">
                    bring fun to players, globally.
                  </span>
                </p>
              </div>
            </Col>
            <div style={{ border: ` 1px solid rgba(10, 56, 116, 1)` }} />
            <Col xs={12} sm={12} md={12} className="p-4 about-cards">
              <div
                className={`${
                  medium
                    ? "p-5 mx-5"
                    : "d-flex flex-column justify-content-center"
                }`}
              >
                <p className="font-lg font-semibold">Mission</p>
                <p className="font-sm">
                  At Blue Squirrel Studios, we aim to unite gamers globally
                  through original, immersive experiences using VR and mobile
                  platforms at first and later expanding to PC and handhelds.
                  Our commitment to integrity, excellence and inclusivity drives
                  us to build a top-tier team dedicated to delivering what
                  gamers desire. We are committed to one major release each year
                  for the next five years, while actively representing the
                  Indian game development community at global events. Together,
                  we aspire to shape the future of gaming from India and foster
                  an inclusive gaming community worldwide.
                </p>
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      <Container
        fluid
        id="our-games"
        style={{
          minHeight: "100vh",
          background: "#000000",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Row className="h-100" style={{ flex: 1 }}>
          <Col
            className="bg-gray-50 d-flex justify-content-center align-items-center"
            md={6}
            xs={12}
          >
            <img
              className="img-fluid"
              src={domiante_the_skies_poster}
              style={{
                width: medium ? "562px" : "90%",
                height: "auto",
                top: 50,
                left: 50,
              }}
            />
          </Col>
          <Col
            md={6}
            xs={12}
            className="d-flex align-items-center justify-content-center"
          >
            <div
              className="d-flex flex-column align-items-start"
              style={{ maxWidth: "285px" }}
            >
              <p
                className="font-lg font-bold  text-left"
                style={{ letterSpacing: "2.17px" }}
              >
                Dominate the Skies
              </p>
              <div className="font-sm">
                <p style={{ letterSpacing: "1.43px" }}>
                  Experience next-level gameplay with Dominate the Skies, where
                  VR anti-gravity racing collides with intense aerial combat.
                  Navigate challenging environments at breakneck speed or engage
                  in strategic dogfights using advanced anti-gravity technology.
                  This dual-mode VR game offers relentless excitement, immersing
                  you in the cutting-edge world of virtual reality.
                </p>

                <p className="font-bold">What you'll get:</p>
                <ul>
                  <li>Immersive Racing</li>
                  <li>Assault Mode</li>
                  <li>Multiplayer Mode</li>
                  <li>Customization</li>
                </ul>
              </div>
              <div className="mx-auto mb-4">
                <CustomButton />
              </div>
              {showVideo && (
                <div
                  className="w-100 position-fixed"
                  style={{
                    top: 0,
                    left: 0,
                    height: "100vh",
                    backgroundColor: "rgba(0, 0, 0, 0.8)",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    zIndex: 1000,
                  }}
                >
                  <HomeVideoContainer setShowVideo={setShowVideo} />
                </div>
              )}
            </div>
          </Col>
        </Row>
      </Container>
      <Container
        id="team"
        fluid
        className="section-bg d-flex flex-column justify-content-center align-items-center"
        style={{
          minHeight: "100vh",
          width: "100%",
        }}
      >
        <h3
          className="lato font-lg font-bold pt-4"
          style={{ marginBottom: medium ? "48px" : "" }}
        >
          Meet our Founders
        </h3>
        <Container>
          <Row className="w-100 mx-0">
            {cardData.map((card, index) => (
              <Col
                xs={12}
                sm={12}
                md={4}
                key={index}
                className="mb-4 d-flex justify-content-center align-items-center"
              >
                <div
                  className="card-bg"
                  style={{
                    width: medium ? "335px" : "230px",
                    height: "100%",
                    padding: "20px 30px",
                    backdropFilter: "blur(37.6px)",
                    boxShadow: "26px 39px 7.3px -1px rgba(0, 0, 0, 0.39)",
                    // position: "relative",
                    zIndex: 1,
                  }}
                >
                  {/* <div
                    style={{
                      // width: medium ? "247" : "150px",
                      height: medium ? "157px" : "96.22px",
                      backgroundColor: "#787878",
                      marginBottom: "45px",
                    }}
                  ></div> */}
                  <div>
                    <div
                      className="d-flex align-items-center"
                      style={{ marginBottom: "21px" }}
                    >
                      <span
                        className="lato font-bold me-2"
                        style={{
                          fontSize: medium ? "16px" : "9.81px",
                          lineHeight: medium ? "22.4px" : "13.73px",
                          letterSpacing: medium ? "1.24px" : "0.76px",
                        }}
                      >
                        {card.title}
                      </span>
                      <a href={card.linkedinUrl} target="_blank">
                        <img
                          src={linkedin}
                          style={{
                            width: medium ? "24px" : "14.71px",
                            height: medium ? "24px" : "14.71px",
                          }}
                        />
                      </a>
                    </div>
                    <p
                      className="lato"
                      style={{
                        fontSize: medium ? "16px" : "9.81px",
                        lineHeight: medium ? "22.4px" : "13.73px",
                        letterSpacing: medium ? "1.24px" : "0.76px",
                      }}
                    >
                      {card.description}
                    </p>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </Container>
      <Container
        fluid
        className="contact_container px-0 d-flex flex-column contact-us-bgv"
        id="contact"
        style={{
          minHeight: medium ? "100vh" : "80vh",
          backgroundImage: medium
            ? `linear-gradient(to right, rgba(0,0,0,1) 80%,rgba(0,0,0,0.2) 95%), url(${contact_us_bg})`
            : `linear-gradient(to bottom, rgba(0,0,0,1) 80%,rgba(0,0,0,0.4) 95%), url(${contact_us_bg_mobile})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
          backgroundPosition: medium ? "right" : "center bottom",
        }}
      >
        <Row className="gx-0" style={{ flex: 1 }}>
          <Col
            className="d-flex flex-column justify-content-center align-items-center"
            md={9}
            style={{
              background: medium
                ? `linear-gradient(90.66deg, #010101 60.55%, #000000 99.45%)
`
                : "",
            }}
          >
            <div className="px-5 mx-5">
              <h1
                className={`lato font-bold ${
                  medium ? "font-xl" : "font-md text-center"
                }`}
              >
                Contact Us
              </h1>

              <div className="mt-4 mb-5">
                <p
                  className={`lato ${
                    medium ? "font-md text-start" : "font-xxs text-center"
                  }`}
                >
                  Whether you're an investor, publisher, or gamer excited about
                  our latest projects, we would love to hear from you.
                </p>
              </div>
              <ContactUsForm />
            </div>
          </Col>
        </Row>
      </Container>
      <Container fluid className="px-0">
        <Footer />
      </Container>
    </Container>
  );
};

export default Home;
