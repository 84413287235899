import React, { useEffect } from "react";
import { Container, Row, Col, Nav } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  blue_squirrel_logo,
  blue_squirrel_vector,
  discord,
  facebook,
  instagram,
  twitter,
} from "assets";
import { useMediaQuery } from "react-responsive";
import { FaLinkedin, FaYoutube } from "react-icons/fa6";
import { Link } from "react-router-dom";

const Footer = () => {
  const medium = useMediaQuery({ query: "(min-width: 768px)" });

  useEffect(() => {
    // Check if there's a hash in the URL
    const hash = window.location.hash;
    if (hash) {
      // Scroll to the element with the matching ID
      const element = document.querySelector(hash);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, []);
  return (
    <Container fluid className="px-0">
      <Container fluid className="px-0" style={{ position: "relative" }}>
        <Row
          className="justify-content-start mx-0"
          style={{ padding: "40px 0", background: "#1c1c1c" }}
        >
          <Col
            md={1}
            className={`text-left text-md-left ms-5 p-0 d-flex flex-column justify-content-center align-items-start ${
              medium ? "" : "d-none"
            }`}
            style={{
              position: "absolute",
              top: "53px",
              left: "20px",
              width: "152px",
            }}
          >
            <div>
              <img
                src={blue_squirrel_logo}
                alt="Logo 1"
                style={{ width: "152px", height: "50px", cursor: "pointer" }}
                onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
              />
            </div>
          </Col>

          <Col className="d-flex justify-content-center px-0">
            <Row className="mx-0">
              <Col xs={12} md={12}>
                <Nav
                  className={`justify-content-center lato ${
                    medium ? "font-s" : "font-micro"
                  }`}
                >
                  <Nav.Link href="#home" className="text-white">
                    Home
                  </Nav.Link>
                  <Nav.Link href="#about" className="text-white">
                    About us
                  </Nav.Link>
                  <Nav.Link href="#our-games" className="text-white">
                    Our Games
                  </Nav.Link>
                  <Nav.Link href="#team" className="text-white">
                    Team
                  </Nav.Link>
                  <Nav.Link href="#contact" className="text-white">
                    Contact us
                  </Nav.Link>
                  <Nav.Link href="/privacy-policy" className="text-white">
                    Privacy Policy
                  </Nav.Link>
                </Nav>
              </Col>
              <Col
                md={12}
                className="d-flex justify-content-center align-items-center"
              >
                <Row className="text-center mt-3">
                  <Col>
                    <a
                      href="https://discord.gg/hUgYHqcjHT"
                      className="text-light"
                      target="_blank"
                    >
                      <img
                        src={discord}
                        style={{
                          width: medium ? "17.89px" : "9.72px",
                        }}
                      />
                    </a>
                  </Col>
                  <Col>
                    <a
                      href="https://www.instagram.com/bluesquirrelstudios"
                      className="text-light"
                      target="_blank"
                    >
                      <img
                        src={instagram}
                        style={{
                          width: medium ? "16px" : "8.69px",
                        }}
                      />
                    </a>
                  </Col>
                  <Col>
                    <a
                      href="https://x.com/bluesquirrelst"
                      className="text-light"
                      target="_blank"
                    >
                      <img
                        src={twitter}
                        style={{
                          width: medium ? "14.4px" : "7.82px",
                        }}
                      />
                    </a>
                  </Col>
                  <Col>
                    <a
                      href="https://www.youtube.com/@BlueSquirrelStudios"
                      className="text-light"
                      target="_blank"
                    >
                      <FaYoutube
                        style={{
                          width: medium ? "14.4px" : "7.82px",
                        }}
                        color="#a4a4a4"
                      />
                    </a>
                  </Col>
                  <Col>
                    <a
                      href="https://www.linkedin.com/company/blue-squirrel-studios"
                      className="text-light"
                      target="_blank"
                    >
                      <FaLinkedin
                        style={{
                          width: medium ? "16px" : "7.82px",
                        }}
                        color="#a4a4a4"
                      />
                    </a>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <Container fluid className="px-0" style={{ background: "#333333" }}>
        <Row
          className="text-center gx-0 poppins"
          style={{
            padding: "21px 0",
            fontSize: medium ? "12px" : "3.88px",
            lineHeight: medium ? "18px" : "5.82px",
            letterSpacing: medium ? "0.4px" : "0.13px",
          }}
        >
          <Col>CIN : U58203PN2024PTC234376</Col>
          <Col>&#169; Blue Squirrel Studios Private Limited</Col>
          <Col>Made in India with ❤️</Col>
        </Row>
      </Container>
    </Container>
  );
};

export default Footer;
